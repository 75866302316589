.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d8d8d8a4;
  z-index: 10000;
}
.wrapper-2 {
  display: flex;
  width: 100%;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  height: 100%;
}

.container-box {
  display: flex;
  flex: 1;
  width: 55%;
  padding: 2rem;
  background: #ffffff;
  z-index: 10;
  box-sizing: border-box;
  min-width: min-content;
  overflow: auto;
}

.wrapper-2 .container-box {
  box-sizing: content-box;
}

.donate-chose {
  margin: auto;
}

.donate-chose h1,
.title h1,
.title h1 span {
  font-weight: 800;
  font-size: 1.75rem;
  color: #182f4b;
  margin-bottom: 0.75rem;
}

.title h1 span {
  color: #0f75bc;
}

.container-box span.subscription {
  font-size: 21px;
  line-height: 140%;
  color: #aebbd4;
}

.container-box span.subscription:before {
  content: "*";
  position: absolute;
  left: -40px;
  top: 5px;
  font-size: 35px;
}

input.form {
  color: #182f4b;
  font-weight: bold;
}

.required {
  width: 48%;
}



.required::before {
  content: "";
  position: absolute;
  top: 12px;
  right: 20px;
  font-weight: 800;
  font-size: 35px;
  color: #aebbd4;
  width: 12px;
  height: 12px;
  background-image: url("images/required.png");
  background-size: cover;
  z-index: 1;
}

.amount {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
  justify-content: center;
}

.amount-item {
  display: inline-block;
  width: calc(33% - 1rem);
  box-sizing: border-box;
  margin: 0.5rem;
  flex: 0 1 calc(33% - 1rem);
}

.amount-item .box {
  padding: 1rem;
  background: #ffffff;
  box-shadow: 0px 3px 13px rgba(27, 19, 106, 0.04),
    0px 5px 60px 10px rgba(27, 19, 106, 0.02),
    0px 3px 40px 4px rgba(27, 19, 106, 0.04);
  border-radius: 10px;
  cursor: pointer;
}

.amount-item .box:hover {
  box-shadow: 0px 3px 13px rgba(27, 19, 106, 0.04),
    0px 5px 60px 10px rgba(27, 19, 106, 0.02),
    0px 3px 40px 4px rgba(27, 19, 106, 0);
}

.amount-item .box .icon {
  border-radius: 50%;
  background: #0f75bc3d;
  width: 3rem;
  height: 3rem;
  text-align: center;
  margin-bottom: 0.75rem;
}

.amount-item .box .icon span {
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 3rem;
}

.amount-item .box p {
  font-weight: bold;
  font-size: 1.75rem;
  color: #182f4b;
}

.container-box .or {
  position: relative;
  margin: 20px 0;
  text-align: center;
}

.container-box .or hr {
  margin: 0 10px;
  border-color: #c3c6cb42;
}

.container-box .or div {
  width: 60px;
  background: #ffffff;
  font-size: 21px;
  color: #c3c6cb;
}

.change-donation-type,
.container-box .or div {
  position: absolute;
  margin: 0;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.change-donation-type-text {
  font-size: 21px;
  line-height: 160%;
  color: #0f75bc;
  margin-right: 43px;
}

.swipe-card {
  background: #182f4b;
}

.swipe-card h1 {
  font-weight: 800;
  font-size: 3rem;
  color: #ffffff;
      width: 384px;
    margin: auto 0 1rem;
}

.swipe-card p {
  font-size: 21px;
  color: #ffffff;
  margin: 10px 0;
}

.swipe-card a {
  text-decoration: underline !important;
  font-weight: bold;
  font-size: 21px;
  text-align: center;
  color: #ffffff;
}

.icon-back,
.icon-back-blue,
.icon-back-blue-crosse {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.icon-back {
  background: #fdf3ed42;
  background-image: url("images/icon-back.png");
}

.icon-back-blue {
  background: rgba(15, 117, 188, 0.1);
  background-image: url("images/icon-arrow-blue.png");
  margin-left: 0;
}

.icon-back-blue-crosse {
  background: #569bcb;
  background-image: url("images/icon-crosse.png");
  position: absolute;
  right: 50px;
  top: 0;
}

.icon-back,
.icon-back-blue,
.icon-back-blue-crosse {
  background-position: center center;
  background-repeat: no-repeat;
}

.card-box {
  max-height: 200px;
}

.card-form {
  background: #f6f8fc;
  border-radius: 10px;
  padding: 35px 25px;
  box-sizing: border-box;
  border: 1px solid #f6f8fc;
}

.card-form.border-red {
  border-color: red;
}

.card-form,
.card-form input {
  color: #aebbd4;
  font-weight: bold;
  font-size: 21px;
}

.card-form input {
  color: #182f4b;
  background-color: #f6f8fc;
  background-clip: padding-box;
  border: 1px solid #f6f8fc;
}

.card-form input::placeholder,
input.form::placeholder {
  color: #aebbd4;
}

input.card-number {
  width: 200px;
}

input.card-month,
input.card-year,
input.card-cvc,
input.card-zip {
  margin: 0 5px;
}

input.card-month,
input.card-year {
  width: 35px;
}

input.card-cvc,
input.card-zip {
  width: 45px;
}

.card-text {
  font-size: 21px;
  line-height: 160%;
  color: #aebbd4;
  padding-left: 40px;
  box-sizing: border-box;
}

.card-text::before {
  content: "*";
  position: absolute;
  left: 0px;
}

.final-box label {
  font-weight: bold;
  font-size: 21px;
  color: #aebbd5;
}

.final-box p {
  font-weight: bold;
  font-size: 28px;
  color: #182f4b;
}

.blue-bg {
  min-height: 100vh;
  background: #0f75bc;
}

.blue-text {
  color: #0f75bc;
  font-family: Lato;
font-style: normal;
font-weight: bold;
font-size: 28px;
line-height: 160%;
}

@media screen and (max-width: 900px) {
  .container-box {
    width: 100%;
  }
}




@media screen and (max-width: 1920px) {
  .keyboard_div {
    /* width: 48% !important; */
    width: 100% !important;
  }
  .keyboard_div_card {
    width: 48% !important;
    /* width: 100% !important; */
  }
}

@media screen and (max-width: 900px) {
  .keyboard_div {
    /* width: 91% !important; */
    width: 100% !important;
  }
  .keyboard_div_card {
    width: 91% !important;
    /* width: 100% !important; */
  }
}

@media screen and (max-width: 768px) {
  .keyboard_div {
    /* width: 89% !important; */
    width: 100% !important;
  }
  .keyboard_div_card {
    width: 89% !important;
    /* width: 100% !important; */
  }
}

@media screen and (max-width: 452px) {
  .keyboard_div {
    /* width: 100% !important; */
    /* display: none; */
    visibility: hidden;
  }

  .info_form_div {
    max-height: 100% !important;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.info_form_div::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.info_form_div {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.hg-button {
  height: 60px !important;
}

.hg-button-bksp {
  width: 10% !important;
}