.loading{
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d8d8d8a4;
    z-index: 10000;
}
.wrapper-bg-image {
    /*background-image: url("images/bg01.png");*/
    background-size: cover;
    background-position: center center;
    background-attachment: fixed
}

.wrapper,
.wrapper-bg {
    height: 100vh;
}

.container {
    position: relative;
    width: 636px;
    background: #ffffff;
    margin: 0 auto;
    margin-top: auto;
    padding: 100px 100px 40px 100px;
    box-sizing: border-box;
    box-shadow: 0px 3px 13px rgba(27, 19, 106, 0.04), 0px 5px 60px 10px rgba(27, 19, 106, 0.02), 0px 3px 40px 4px rgba(27, 19, 106, 0.04);
    border-radius: 15px 15px 0 0;
}

.container .a-icon-back {
    position: absolute;
    top: 20px;
    left: 20px;
}

.container .icon-back {
    margin: 0;
    background: #ee933eb8;
    background-image: url("../Option2/images/icon-back.png");
    background-position: center center;
    background-repeat: no-repeat;
}

.title h1 {
    text-align: center;
}

input.donate-amount {
    color: #000000 !important;
    font-weight: bold;
}

div.checkbox {
    margin: 0 20px;
    cursor: pointer;
    position: relative;
    width: 58px;
    height: 28px;
    padding: 5px;
    background: #FFFFFF;
    box-shadow: 0px 3px 13px rgba(27, 19, 106, 0.08), 0px 5px 60px 10px rgba(27, 19, 106, 0.02), 0px 3px 40px 4px rgba(27, 19, 106, 0.04);
    border-radius: 100px;
}

.checkbox .checkbox-ball {
    float: left;
    width: 28px;
    height: 28px;
    background: #EE933E;
    border-radius: 100px;
}

label.checkbox {
    font-weight: bold;
    font-size: 21px;
    color: #000000;
}

.icon-arrow {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 86px;
    height: 45px;
    background-image: url("images/icon-arrow.png");
}

.card-enter {
    font-weight: bold;
    font-size: 21px;
    color: #EE933E;
    text-decoration: underline !important;
}

