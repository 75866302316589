.video-box, .video-box-bg {
    position: relative;
    width: 45%;
    overflow: hidden;
}
.video-box-bg {
    height: 100%;
    position: fixed;
    background-size: cover;
    background-position: center center;
}

.video-box video{
    height: 100%;
    width: 100%;
    z-index: 0;
    position: fixed;
    transform: translate(-55%, 0);
    object-fit: cover;
}

.video-box .play {
    position: absolute;
    margin: 0;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border: 2px solid #0F75BC;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    cursor: pointer;
    background-image: url('images/play.png');
    background-position: center center;
    background-repeat: no-repeat;
}

.video-box .paus {
    position: absolute;
    margin: 0;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border: 2px solid #0F75BC;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    cursor: pointer;
    background-image: url('images/paus.png');
    background-position: center center;
    background-repeat: no-repeat;
}

.alert-enter {
    opacity: 0;
    transform: scale(0.9);
}
.alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}
.alert-exit {
    opacity: 1;
}
.alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

@media screen and (max-width: 900px) {
    .video-box {
        display: none;
    }
}