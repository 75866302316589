#root {
  height: 100vh;
}

html {
  min-height: fit-content;
  overflow: hidden;
}

* {
  margin: 0;
  font-family: "Lato", sans-serif;
}

input:focus {
  outline: 0;
}

a {
  text-decoration: none !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}

.mt-3 {
  margin-right: 1rem !important;
}

.mt-4 {
  margin-right: 1.5rem !important;
}

.mt-5 {
  margin-right: 3rem !important;
}

.f-right {
  float: right !important;
}

.p-relative {
  position: relative !important;
}

.text-center {
  text-align: center;
}

.wrapper {
  display: flex;
  width: 100%;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 1rem 3rem;
  font-size: 1.3rem;
  line-height: 2.5rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-transform: none;
  cursor: pointer;
}

.btn:hover {
  color: #fff;
  background-color: #0f75bc;
  border-color: #007bff;
}

.btn-primary {
  color: #fff;
  background-color: #0f75bc;
  border-color: #007bff;
  border-radius: 10px;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-secondary {
  color: #fff;
  background-color: #ee933e;
  border-color: #ee933e;
}

.btn-secondary:hover {
  background-color: #ee933eab;
  border-color: #ee933eab;
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  background-color: #e2e6ea;
  border-color: #f8f9fa;
}

.btn-full {
  box-sizing: border-box;
  width: 100%;
}

input.form,
input.donate-amount {
  width: 100%;
  position: relative;
  display: block;
  padding: 1.3rem 0.75rem;
  font-size: 21px !important;
  line-height: 1.5;
  color: #182f4b;
  background: #f6f8fc;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-sizing: border-box;
  text-align: center;
  margin-top: -1px;
}

.border-red {
  border-color: red !important;
}

.word-wrap {
  word-wrap: break-word;
  word-break: break-all;
}

.btn-go {
  box-sizing: border-box;
}

.name-input {
  text-transform: capitalize;
}

.currency_select select {
  width: 100%;
  padding: 22px 15px;
  margin-bottom: 0;
  float: left;
  width: 100%;
  border: 1px solid #d1d1d1;
  font-size: 20px;
  position: relative;
  z-index: 999999999999999;
}

.resetButtonDiv {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
