.carrot-video-box, .carrot-video-box-bg {
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}
.carrot-video-box-bg {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center center;
    object-fit: cover;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.carrot-video-box video{
   object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
